import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from '../global';
import { Observable } from 'rxjs';
import { API_URL } from "../../../environments/api-url";
import { AuthService } from 'src/app/services/auth.service';

// implemented #mohsin-dev

@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    public constructor(private httpclient: HttpClient,
        private _authService: AuthService) {
    }
    updateProfile(details): Observable<any> {
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateProfile, { details: details });
    }
    updateSettings(update): Observable<any> {
        const auth = {
            id: this._authService.currentUser._id,
            usertype: this._authService.currentUser.user_type,
            auth_key: this._authService.currentUser.auth_key,
            is_activated: this._authService.currentUser.is_activated
        };
        let detail = {
            ...auth,
            updateObject: update
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateSettings, { details: detail });
    }
    sendMobileVerificationCode(details): Observable<any> {
        return this.httpclient
            .post(global.url + API_URL.AUTH.sendMobileVerificationCode, { details: details });
    }
    sendEmailVerificationCode(details): Observable<any> {
        return this.httpclient
            .post(global.url + API_URL.AUTH.sendVerificationCode, { details: details });
    }
    checkVerificationCode(details = {}) {
        return this.httpclient
            .post(global.url + API_URL.AUTH.checkVerificationCode, { details: details });
    }
    getTwoFactorSettingAllow(details): Observable<any> {
        return this.httpclient
            .post(global.url + API_URL.AUTH.getTwoFactorSettingAllow, { details: details });
    }
    updateOtherSettings(setting) {
        const auth = {
            id: this._authService.currentUser._id,
            usertype: this._authService.currentUser.user_type,
            auth_key: this._authService.currentUser.auth_key,
            is_activated: this._authService.currentUser.is_activated
        };
        let detail = {
            ...auth,
            otherSettings: setting
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateOtherSettings, { details: detail });
    }

    deleteUser(id) {
        const detail = {
            id: this._authService.currentUser._id,
            usertype: this._authService.currentUser.user_type,
            auth_key: this._authService.currentUser.auth_key,
            user_id : id
        };

        return this.httpclient
            .post(global.url + API_URL.AUTH.deleteUser, { details: detail });
    }

    updateUserType(update,additionalRole?): Observable<any> {
        const auth = {
            id: this._authService.currentUser._id,
            usertype: this._authService.currentUser.user_type,
            auth_key: this._authService.currentUser.auth_key,
            is_activated: this._authService.currentUser.is_activated
        };
        let detail = {
            ...auth,
            user_id: update.user_id,
            user_type: update.user_type,
            additionalRole: additionalRole
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateUserType, { details: detail });
    }

    updateAdminType(update): Observable<any> {
        const auth = {
            id: this._authService.currentUser._id,
            usertype: this._authService.currentUser.user_type,
            auth_key: this._authService.currentUser.auth_key,
            is_activated: this._authService.currentUser.is_activated
        };
        let detail = {
            ...auth,
            user_id: update.user_id,
            admin_type: update.admin_type
        }
        return this.httpclient
            .post(global.url + API_URL.AUTH.updateAdminType, { details: detail });
    }

}
